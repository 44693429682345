










export default

	props: block: Object

	data: ->
		loaded: false

	mounted: ->
		@$wait 2000, =>
			@loaded = true

	computed:

		parsedHTML: -> @block.html

